import React, { Component } from 'react'

import Body from '../body/Body'

class Main extends Component {
    render() {
        return (
            <div>
                <Body />
            </div>
        )
    }
}

export default Main
